import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { ProjectStageDepositStatusEnum } from '@/core/packages/shared-library';
// import { PROJECT_STAGES_TASKS_ROUTE } from '../routes';
// import { PROJECT_DETAILS } from '@/modules/project-details/routes';
import { PROJECT_STAGE_DEPOSITS_STORE } from '../../../store/modules/project-stage-deposits';
import { PROJECT_POST_ROUTE } from '../../project-post/routes';
export default defineComponent({
    name: 'project-stage-deposit-page',
    data: () => {
        return {
            paymentInitationId: null,
        };
    },
    created() {
        this.initialize();
    },
    methods: {
        ...mapActions(PROJECT_STAGE_DEPOSITS_STORE, ['verifyProjectStageDeposit']),
        // eslint-disable-next-line consistent-return
        async initialize() {
            let paymentInitationId = '';
            if (this.$route.query && this.$route.query.paymentInitiationId) {
                paymentInitationId = this.$route.query.paymentInitiationId;
                this.paymentInitationId = paymentInitationId;
            }
            else {
                this.$router.push({ name: PROJECT_POST_ROUTE });
                return false;
            }
            // check if paymentInitationId matches a project stage
            try {
                const responseData = await this.verifyProjectStageDeposit(paymentInitationId);
                switch (responseData.status) {
                    case ProjectStageDepositStatusEnum.VERIFIED:
                        this.$notify.success({
                            message: 'Your payment has successfully been verified.'
                        });
                        break;
                    case ProjectStageDepositStatusEnum.IN_PROCESS:
                    case ProjectStageDepositStatusEnum.STARTED:
                        this.$notify.warning({
                            message: 'Your payment is currently being processed.'
                        });
                        break;
                    case ProjectStageDepositStatusEnum.REJECTED:
                        this.$notify.error({
                            message: 'Your payment was rejected. Please Try Again'
                        });
                        break;
                    default:
                        this.$notify.error({
                            message: 'Verification error. Please Try Again'
                        });
                }
                setTimeout(() => {
                    const projectRefId = responseData?.projectStageDeposit?.projectStage?.projectQuote?.project?.refId;
                    // (this as any).$router.push({ name: PROJECT_DETAILS, params: { projectRefId }, query: { paymentSuccess: '1' } });
                    this.$router.push(`/projects/${projectRefId}/project-details/overview?paymentSuccess=1`);
                });
            }
            catch (e) {
                this.$notify.error({
                    message: 'Error verifying stage deposit. Please Try Again'
                });
                /* const { projectId } = (this as any).$route.params;
                (this as any).$router.push({ name: PROJECT_STAGES_TASKS_ROUTE, params: { projectId } }); */
            }
        }
    }
});
